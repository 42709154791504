import { useEffect, useState, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { addDays } from "date-fns";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CommonButton from "../../utilities/formElements/commonButton/CommonButton";
import CommonInput from "../../utilities/formElements/commonInput/CommonInput";
import {
  toastMessageError,
  toastMessageSuccess,
} from "../../utilities/commonToastMessage";

import {
  emptyLocalStorageOnLogout,
  trakingCodeGenrator,
} from "../../../util/helper";
import {
  connectSocketForConversionSetup,
  disconnectSocket,
  socketForConversionSetup,
  disconnectSocketForConversionSetup
} from "../../../util/socket";
import storage from "../../../util/storage";
import {
  getSubscriptionPageViewCount,
  subscriptionPageViews,
} from "../../../util/metricsModal";

import {
  ISettings,
  IRadioButtonValue,
  BillingHistory,
  ISubscription,
} from "../../../Interfaces/SettingInterface";
import { IPaymentData } from "../../../Interfaces/CommonInterface";

import { subscription } from "../../../services/susbscription";
import { session } from "../../../services/session";
import { editUserDetail, resetSettings } from "../../../services/user";

import CommonModel from "../../commonModel/CommonModel";
import Payment from "../paymentModel/Payment";
import PaymentMethod from "../paymentModel/PaymentMethod";

import ActionType from "../../../resources/enums";
import {
  speedOptions,
  charge,
  friction,
  fourteenDaysPlan,
  DEFAULT_VALUE,
  baseJumpPlan,
} from "../../../constants/commonConstants";

import ClipIcon from "../../../assets/images/clippy.svg";
import config from "../../../config/config";
import ChangePassword from "../../commonModel/ChangePassword";
import useOnlineStatus from "../../utilities/useOnlineStatus";
import Header from "../../header/Header";
import InfoModel from "../../commonModel/InfoModel";
import TrialFinishModel from "../../commonModel/TrialFinishModel";
import ClusterPreview from "./ClusterPreview";
import ROUTES from "../../../constants/routes";
import "./Settings.scss";

const Settings = () => {
  const { t: translation } = useTranslation();
  const dispatch = useDispatch();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const authReducer = useSelector((state: any) => state?.AuthReducer);

  const { handleSubmit, reset, control, formState } = useForm({
    mode: "onChange",
  });

  const publishableKey = config.stripePublishableKey;
  const stripePromise = loadStripe(publishableKey as string);

  const [settingstab, setSettingstab] = useState({
    applicationTab: false,
    billingTab: false,
    accountTab: true,
  });
  const [boolValue, setBoolValue] = useState({
    passwordModel: false,
    deleteAcountModel: false,
    paymentModel: false,
    windowInstance: false,
    changePaymentModel: false,
    trialModel: false,
  });
  const [loading, setLoading] = useState({
    savePreferenceLoading: false,
    resetSettingLoading: false,
  });
  const [selectedSpeedOption, setSelectedSpeedOption] =
    useState<IRadioButtonValue>(speedOptions[0]);

  const [totalPageViewCount, setTotalPageViewCount] = useState();
  const [trackingCode, setTrackingCode] = useState("");
  const [billingData, setBillingData] = useState<BillingHistory>();
  const [paymentMethod, setPaymentMethod] = useState<IPaymentData>();
  const [isAccountSuspended, setIsAccountSuspended] = useState(true);

  const isOnline = useOnlineStatus();
  const siteId = authReducer.authData.site._id;
  const socketOptions = {
    query: `siteId=${siteId}&role=admin&roomStatus=conversion-setup`,
  };
  const isFormDirty = formState.isDirty; //used to check if the value(preference) is changed or not
  const DEFAULT_D3CIRCLE_CONSTRAINT = {
    gravity: authReducer?.bubbleClusterParameters.gravity,
    charge: authReducer?.bubbleClusterParameters.charge,
    friction: authReducer?.bubbleClusterParameters.friction,
    speed: authReducer?.bubbleClusterParameters.speed,
  };
  const [windowInstance, setWindowInstance] = useState<boolean>(false);
  const [showCongratsModel, setShowCongratsModel] = useState<boolean>(false);
  const [closed, setClosed] = useState<boolean>(true);
  const [chargeValue, setChargeValue] = useState(authReducer?.bubbleClusterParameters.charge);
  const [chargePreviewRange, setChargePreviewRange] = useState();
  
  let siteWindow: any;
  // @ts-ignore
  let siteConnected = false;
  let timer;
  useEffect(() => {
    getSubscriptionPageViewCount(authReducer.authData._id);
    getTrackingCode(siteId);
    fetchData();
    getPaymentMethod();
  }, []);

  useEffect(() => {
    fetchData();
  }, [boolValue.paymentModel]);
  useEffect(() => {
    setClosed(false);
  }, [authReducer?.isClose]);

  /**
   * to copy TRACKING CODE
   */
  const handleCopy = () => {
    if (textAreaRef.current) {
      textAreaRef.current.select();
      document.execCommand("copy");
    }
  };

  const getTrackingCode = (siteId: string) => {
    const trackingCodeData = trakingCodeGenrator(siteId);
    setTrackingCode(trackingCodeData);
  };

  /**
   *
   * save preference
   */
  const onSubmit = async (data: ISettings) => {
    setLoading({
      ...loading,
      savePreferenceLoading: true,
    });

    const result = await editUserDetail({
      userId: authReducer.authData._id,
      settings: {
        gravity: DEFAULT_D3CIRCLE_CONSTRAINT.gravity,
        speed: selectedSpeedOption.value,
        charge:-(data.charge),
        friction: data.friction,
      },
    });

    if (result.data) {
      toastMessageSuccess(
        translation("settings_saves_sucecesfully"),
        "settingsSaved"
      );
      dispatch({
        type: ActionType.UPDATECLUSTER,
        payload: {
          charge: result.data.settings.charge,
          friction: result.data.settings.friction,
          speed: result.data.settings.speed,
          gravity: 0.2,
        },
      });
      storage.set("bubbleParameters", {
        charge: Number(result.data.settings.charge),
        friction: Number(data.friction),
        speed: selectedSpeedOption.value,
        gravity: 0.2,
      });
    } else {
      toastMessageError(
        translation("something_went_wrong"),
        "settingsNotSaved"
      );
      setLoading({
        savePreferenceLoading: false,
        resetSettingLoading: false,
      });
    }
    setLoading({
      ...loading,
    });
  };

  /**
   *
   * reset settings
   */
  const handleResetSettings = async () => {
    setLoading({
      ...loading,
      resetSettingLoading: true,
    });
    const resetData = await resetSettings({ userId: authReducer.authData._id });
    if (resetData.data) {
      toastMessageSuccess(
        translation("reset_settings_succesfully"),
        "resetSettings"
      );
      reset({
        charge: resetData?.data?.settings?.charge,
        friction: resetData?.data?.settings?.friction,
        speed: resetData?.data?.settings?.speed,
      });
      dispatch({
        type: ActionType.UPDATECLUSTER,
        payload: {
          charge: resetData?.data?.settings?.charge,
          friction: resetData?.data?.settings?.friction,
          speed: resetData?.data?.settings?.speed,
          gravity: DEFAULT_D3CIRCLE_CONSTRAINT.gravity,
        },
      });
      storage.set("bubbleParameters", {
        charge: Number(resetData?.data?.settings?.charge),
        friction: Number(resetData?.data?.settings?.friction),
        speed: resetData?.data?.settings?.speed,
        gravity: 0.2,
      });
    } else {
      toastMessageError(
        translation("something_went_wrong"),
        "resetSettingError"
      );
      setLoading({
        savePreferenceLoading: false,
        resetSettingLoading: false,
      });
    }
    setLoading({
      ...loading,
    });
  };

  /**
   *
   * subscription details
   */
  const fetchData = async () => {
    const userId = authReducer.authData._id;
    const sessionData = await session({
      jsonprc: "2.0",
      method: "getTotalPageViewCount",
      params: {
        siteId: siteId,
      },
      id: 0,
    });
    setTotalPageViewCount(sessionData.data?.result);

    const data = await subscription({
      jsonprc: "2.0",
      method: "getAccountRecord",
      params: {
        userId: authReducer.authData._id,
      },
      id: 0,
    });

    const billingHistory = await subscription({
      jsonprc: "2.0",
      method: "getBillingHistory",
      params: {
        userId: authReducer.authData._id,
      },
      id: 0,
    });
    setBillingData(billingHistory?.data?.result);
    getPaymentMethod();
  };

  const getPaymentMethod = async () => {
    const getPaymentMethod = await subscription({
      jsonprc: "2.0",
      method: "getPaymentMethod",
      params: {
        userId: authReducer.authData._id,
      },
      id: 0,
    });
    setPaymentMethod(getPaymentMethod?.data?.result);
  };

  const verifyTrackingCode = () => {
    connectSocketForConversionSetup(socketOptions);
    try {
      siteWindow = window.open(
        authReducer.authData.site.siteUrl + "?verifySkydive=true",
        "_blank"
      );
      socketForConversionSetup.on("site-info", (payload: any) => {
        setWindowInstance(true);

        siteConnected = true;
        if (
          payload &&
          payload.message &&
          payload.message === "Site Connected Successfully"
        ) {
          setShowCongratsModel(true);
          siteWindow.close();
          clearTimeout(timer);
          disconnectSocketForConversionSetup();
          return;
        } else {
          socketConnectionCheck();
        }
        siteWindow.close();
      });
      if (!siteConnected) {
        socketConnectionCheck();
      }
    } catch (err) {}
  };
  const socketConnectionCheck = () => {
    timer = setTimeout(() => {
      socketForConversionSetup.disconnect();
      toastMessageError(
        translation("verification_time_out"),
        "verificationTimeOutError"
      );

      siteWindow.close();
      disconnectSocketForConversionSetup();
    }, DEFAULT_VALUE.SETUP_TIMEOUT);
  };

  const handleShowPreview = async (data:any) =>{
    setChargePreviewRange(data);
  };

  return (
    <div className="settings-main">
      <Header />
      {showCongratsModel && (
        <InfoModel
          closeModel={() => {
            setShowCongratsModel(false);
          }}
          name="success"
        />
      )}
      {isOnline ? (
        <>
          <div className="container-fluid pt30">
            <Link
              to={
                window.location.pathname.split("/")[
                  window.location.pathname.split("/").length - 1
                ] === "settings"
                  ? ROUTES.ANALYTICS_VIEW.replace("/:id", "")
                  : ROUTES.ANALYTICS_VIEW.replace(
                      ":id",
                      window.location.pathname.split("/")[
                        window.location.pathname.split("/").length - 1
                      ]
                    )
              }
              className="link-btn"
            >
              <span>
                <svg
                  id="Layer_1"
                  height="11"
                  width="11"
                  viewBox="0 0 512 512"
                  fill="#555"
                  xmlns="http://www.w3.org/2000/svg"
                  data-name="Layer 1"
                >
                  <path d="m390.627 54.627-201.372 201.373 201.372 201.373a32 32 0 1 1 -45.254 45.254l-224-224a32 32 0 0 1 0-45.254l224-224a32 32 0 0 1 45.254 45.254z" />
                </svg>
              </span>{" "}
              {translation("back_to_main_view")}
            </Link>
            <h3>{translation("settings")}</h3>
            <div className="settings-tab-section">
              <div className="settings-tab settings-btns-wrapper ">
                <div>
                  <CommonButton
                    className={settingstab.accountTab ? "active" : ""}
                    label={translation("account")}
                    onClick={() =>
                      setSettingstab({
                        applicationTab: false,
                        billingTab: false,
                        accountTab: true,
                      })
                    }
                  />
                  <CommonButton
                    className={settingstab.applicationTab ? "active" : ""}
                    label={translation("application")}
                    onClick={() =>
                      setSettingstab({
                        applicationTab: true,
                        billingTab: false,
                        accountTab: false,
                      })
                    }
                  />
                  <CommonButton
                    className={settingstab.billingTab ? "active" : ""}
                    label={translation("billing")}
                    onClick={() =>
                      setSettingstab({
                        applicationTab: false,
                        billingTab: true,
                        accountTab: false,
                      })
                    }
                  />
                </div>

                {authReducer?.accountRecord?.flags?.suspended &&
                  isAccountSuspended && (
                    <>
                      <div className="account-close">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 48 48"
                        >
                          <g fill="none" fill-rule="evenodd">
                            <circle cx="24" cy="24" r="24" fill="#D86060" />
                            <path
                              fill="#F3F3F3"
                              fill-rule="nonzero"
                              d="M27.69 27.023L28.485 7h-7.11l.888 20.023h5.427zM24.977 39c1.122 0 2.074-.384 2.853-1.153.78-.768 1.17-1.68 1.17-2.734 0-1.055-.39-1.959-1.17-2.712-.78-.753-1.73-1.13-2.853-1.13-1.092 0-2.028.377-2.807 1.13-.78.753-1.17 1.657-1.17 2.712 0 1.055.39 1.966 1.17 2.734.78.769 1.715 1.153 2.807 1.153z"
                            />
                          </g>
                        </svg>
                        <p className="auth-msg danger">
                          {translation("your_account_is_currently_suspended")}
                        </p>
                        <p className="auth-msg danger">
                          {translation("your_account_is_currently_suspended")}
                        </p>
                      </div>
                    </>
                  )}
              </div>
              {settingstab.accountTab && (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="subscription-details">
                        <h4>{translation("subscription")}</h4>
                        <table>
                          <tr>
                            <td className="fw-500">{translation("type")}</td>
                            <td>
                              {
                                authReducer?.accountRecord?.subscriptionData
                                  ?.title
                              }
                            </td>
                            <td className="text-end">
                              <CommonButton
                                className="font12"
                                label={translation("change")}
                                onClick={() => {
                                  dispatch({
                                    type: ActionType.SUBSCRIPTIONTITLE,
                                    payload: { title: "" },
                                  });
                                  setClosed(true);
                                  {
                                    authReducer?.accountRecord?.subscriptionData
                                      ?.title === "14 Days Trial"
                                      ? setBoolValue({
                                          trialModel: true,
                                          paymentModel: false,
                                          passwordModel: false,
                                          deleteAcountModel: false,
                                          windowInstance: false,
                                          changePaymentModel: false,
                                        })
                                      : setBoolValue({
                                          paymentModel: true,
                                          passwordModel: false,
                                          deleteAcountModel: false,
                                          windowInstance: false,
                                          changePaymentModel: false,
                                          trialModel: false,
                                        });
                                  }
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-500">
                              {translation("page_views_used")}
                            </td>
                            <td className="font12">
                              <span className="font14">
                                {authReducer?.accountRecord?.subscriptionData
                                  ?.title === fourteenDaysPlan
                                  ? "Unlimited"
                                  : subscriptionPageViews}
                              </span>{" "}
                              {authReducer?.accountRecord?.subscriptionData
                                ?.title === fourteenDaysPlan
                                ? ""
                                : `of ${
                                    authReducer?.accountRecord?.subscriptionData
                                      ?.pageViewsLimit / 1000
                                  }k ${translation(
                                    "current_period_page_view"
                                  )}`}
                            </td>
                            <td className="font10 text-end detail-small-text">
                              {(authReducer?.accountRecord?.subscriptionData
                                ?.title === fourteenDaysPlan || authReducer?.accountRecord?.subscriptionData.title ===baseJumpPlan)
                                ? ""
                                : translation("next_pay_period")}

                              {(authReducer?.accountRecord?.subscriptionData
                                ?.title === fourteenDaysPlan ||authReducer?.accountRecord?.subscriptionData.title ===baseJumpPlan)
                                ? ""
                                : addDays(
                                    new Date(
                                      authReducer?.accountRecord?.changeDate
                                    ),
                                    30
                                  ).toLocaleDateString()}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-500">
                              {translation("overall_page_views")}
                            </td>
                            <td>
                              {totalPageViewCount ? totalPageViewCount : "0"}
                            </td>
                            <td className="font10 text-end detail-small-text">
                              {translation("since")}{" "}
                              {authReducer?.accountRecord?.createdAt &&
                                new Date(
                                  authReducer?.accountRecord?.createdAt
                                ).toLocaleDateString()}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-500">
                              {translation("data_storage_limit")}
                            </td>
                            <td>
                              {authReducer?.accountRecord?.subscriptionData
                                ?.title === fourteenDaysPlan ? (
                                "n/a"
                              ) : (
                                <>
                                  {" "}
                                  {
                                    authReducer?.accountRecord?.subscriptionData
                                      ?.storageDays
                                  }
                                  {" days"}{" "}
                                </>
                              )}
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td className="fw-500">
                              {translation("activation_date")}
                            </td>
                            <td>
                              {authReducer?.accountRecord?.flags
                                .activationDate &&
                                new Date(
                                  authReducer?.accountRecord?.flags.activationDate
                                ).toLocaleDateString()}
                            </td>
                            <td className="font10 text-end detail-small-text">
                              {authReducer?.accountRecord?.subscriptionData
                                ?.title === fourteenDaysPlan ? (
                                ""
                              ) : (
                                <>
                                  {translation("trial_period")}
                                  {authReducer?.accountRecord?.createdAt &&
                                    new Date(
                                      authReducer?.accountRecord?.createdAt
                                    ).toLocaleDateString()}{" "}
                                  -{" "}
                                  {authReducer?.accountRecord?.flags
                                    ?.trialFinishDate &&
                                    new Date(
                                      authReducer?.accountRecord?.flags?.trialFinishDate
                                    ).toLocaleDateString()}
                                </>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <CommonButton
                                className="font12"
                                label={translation("logoff")}
                                onClick={() => {
                                  dispatch({
                                    type: ActionType.LOGOUT,
                                    payload: "",
                                  });
                                  disconnectSocket();
                                  emptyLocalStorageOnLogout();
                                }}
                              />
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="subscription-details">
                        <h4>{translation("site")}</h4>
                        <p>{authReducer.authData.site.host}</p>
                        <div className="form-group textarea-group">
                          <textarea
                            ref={textAreaRef} // Assign the ref to the textarea element
                            rows={8}
                            placeholder={translation("place_your_script_here")}
                            className="form-control"
                            defaultValue={trackingCode}
                            readOnly
                          />
                          <span className="copy-icon" onClick={handleCopy}>
                            <img src={ClipIcon} alt="ClipIcon" />

                            <div className="textarea-tooltip">
                              Copy to clipboard
                              <div className="arrow-right"></div>
                            </div>
                          </span>
                        </div>
                        <CommonButton
                          className="transparent-btn"
                          label={translation("verify_my_code")}
                          onClick={() => verifyTrackingCode()}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="subscription-table">
                    <h4>{translation("users")}</h4>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <td>{translation("name")}</td>
                            <td>{translation("email")}</td>
                            <td>{translation("password")}</td>
                            <td>{translation("role")}</td>
                            <td>{translation("status")}</td>
                            {/* <td>{translation("actions")}</td> */}
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>{authReducer.authData.firstname}</td>
                            <td>{authReducer.authData.email}</td>
                            <td>
                              <div className="pass-show-btn">
                                <p>*******</p>
                                <CommonButton
                                  className="transparent-btn"
                                  label={translation("change")}
                                  onClick={() => {
                                    setBoolValue({
                                      passwordModel: true,
                                      deleteAcountModel: false,
                                      paymentModel: false,
                                      windowInstance: false,
                                      changePaymentModel: false,
                                      trialModel: false,
                                    });
                                  }}
                                />
                              </div>
                            </td>
                            <td>{authReducer.authData.role}</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="delete-row">
                      <CommonButton
                        className="transparent-btn"
                        label={translation("delete_account")}
                        onClick={() => {
                          setBoolValue({
                            passwordModel: false,
                            deleteAcountModel: true,
                            paymentModel: false,
                            windowInstance: false,
                            changePaymentModel: false,
                            trialModel: false,
                          });
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {settingstab.billingTab && (
                <>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="subscription-details">
                        <h4>{translation("payment_details")}</h4>
                        <table>
                          <tr>
                            <td className="fw-500">
                              {translation("payment_method")}
                            </td>
                            <td>
                              {paymentMethod
                                ? `${paymentMethod?.cardBrand} ending in ${paymentMethod?.lastFourDigits}, exp: ${paymentMethod?.expirationMonth}/${paymentMethod?.expirationYear}`
                                : translation("have_not_set_payment_detail")}
                            </td>
                            <td>
                              <CommonButton
                                className={
                                  authReducer?.accountRecord?.flags?.suspended
                                    ? "auth-msg danger"
                                    : "transparent-btn"
                                }
                                label={"UPDATE PAYMENT DETAILS"}
                                onClick={() => {
                                  setBoolValue({
                                    passwordModel: false,
                                    deleteAcountModel: false,
                                    paymentModel: false,
                                    windowInstance: false,
                                    changePaymentModel: true,
                                    trialModel: false,
                                  });
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-500">
                              {translation("billing_period")}
                            </td>
                            <td>
                              {paymentMethod
                                ? translation("thirty_days")
                                : translation("have_not_set_payment_detail")}
                            </td>
                          </tr>
                        </table>
                      </div>

                      <div className="subscription-table">
                        <div className="subscription-heading-align">
                          <h4>{translation("billing_history")}</h4>
                          <p className="header-para">
                            {translation("View_invoices_and_receipts")}
                          </p>
                        </div>
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <td>{translation("date")}</td>
                                <td colSpan={2}>
                                  {translation("description")}
                                </td>
                                <td align="right">{translation("amount")}</td>
                              </tr>
                            </thead>

                            <tbody>
                              {billingData?.length
                                ? billingData.map((item, index) => (
                                    <tr key={index}>
                                      <td>
                                        {item.creationDate &&
                                          new Date(
                                            item.creationDate
                                          ).toLocaleDateString()}
                                      </td>
                                      <td>
                                        {item.planName}(
                                        {item.periodStart &&
                                          new Date(
                                            item.periodStart
                                          ).toLocaleDateString()}{" "}
                                        -{" "}
                                        {item.periodEnd &&
                                          new Date(
                                            item.periodEnd
                                          ).toLocaleDateString()}
                                        )
                                      </td>
                                      <td align="right">
                                        <Link
                                          to={item.pdfLink}
                                          target="_blank"
                                          className="transparent-btn"
                                        >
                                          {translation("invoice")}
                                        </Link>
                                      </td>
                                      <td>
                                        <div className="pass-show-btn justify-content-end">
                                          <p className="m-0">$ {item.amount}</p>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                : translation("no_data_found")}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {settingstab.applicationTab && (
                <form
                  onSubmit={handleSubmit((data) => onSubmit(data as ISettings))}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="subscription-details">
                        <h4>{translation("preferences")}</h4>
                        <div className="row mt-4">
                          <div className="col-md-3">
                            <div className="subscription-radio-group">
                              <h6>{translation("bubble_speed")}</h6>
                              <ul>
                                {speedOptions.map((speedObject, index) => (
                                  <li>
                                    <label key={index}>
                                      <CommonInput
                                        type="radio"
                                        control={control}
                                        name="speed"
                                        value={speedObject.value}
                                        onChange={() =>
                                          setSelectedSpeedOption(speedObject)
                                        }
                                        defaultChecked={
                                          speedObject.value ===
                                          Number(
                                            authReducer.bubbleClusterParameters
                                              .speed
                                          )
                                            ? true
                                            : false
                                        }
                                      />
                                      <span className="padding-left-5 thin-text">
                                        {speedObject.textField}
                                      </span>
                                    </label>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="subscription-radio-group">
                              <h6>{translation("bubble_cluster_spacing")}</h6>
                              <div className="slidecontainer">
                                <h6 className="m-0">
                                  {translation("charge_range")}
                                </h6>
                                <CommonInput
                                  control={control}
                                  name="charge"
                                  value={(DEFAULT_D3CIRCLE_CONSTRAINT.charge)*-1}
                                  defaultValue={
                                   ( DEFAULT_D3CIRCLE_CONSTRAINT.charge)*-1
                                  }
                                  type="range"
                                  min={-(charge.max)}
                                  max={-(charge.min)}
                                  step={1}
                                  onChange={(e) => {setChargeValue(Number(-(e.target.value)));}}
                                />
                              </div>
                              {/* <div className="slidecontainer">
                                <h6 className="m-0">
                                  {translation("friction_range")}
                                </h6>
                                <CommonInput
                                  control={control}
                                  name="friction"
                                  defaultValue={
                                    DEFAULT_D3CIRCLE_CONSTRAINT.friction
                                  }
                                  value={DEFAULT_D3CIRCLE_CONSTRAINT.friction}
                                  type="range"
                                  min={friction.min}
                                  max={friction.max}
                                  step={friction.step}
                                />
                              </div> */}
                            </div>
                            <div className="text-center pt-5">
                            <CommonButton
                            type="button"
                            label="SHOW PREVIEW"
                            className="theme-btn white-btn btn-sm"
                            // loading={loading.resetSettingLoading}
                            // disabled={loading.resetSettingLoading}
                            onClick={()=>{handleShowPreview(chargeValue);}}
                          />
                          </div>
                          </div>
                          <div className="col-md-4 offset-md-1">
                              <div className="subscription-radio-group">
                                <h6>Bubble Cluster Preview</h6>
                                <div className="slidecontainer">
             
                            <ClusterPreview charge={chargePreviewRange? chargePreviewRange:DEFAULT_D3CIRCLE_CONSTRAINT.charge} />
                                </div>
   
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="subscription-bottom-reset-align">
                        <CommonButton
                          type="submit"
                          label={translation("save_preferences")}
                          className="theme-btn success-btn btn-sm"
                          loading={loading.savePreferenceLoading}
                          disabled={
                            !isFormDirty || loading.savePreferenceLoading
                          }
                        />
                        <CommonButton
                          type="button"
                          label={translation("reset")}
                          className="theme-btn white-btn btn-sm"
                          loading={loading.resetSettingLoading}
                          disabled={loading.resetSettingLoading}
                          onClick={handleResetSettings}
                        />
                                           
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>

          {boolValue.passwordModel && (
            <ChangePassword
              title={"Change Password"}
              onClose={() => {
                setBoolValue({
                  ...boolValue,
                  passwordModel: false,
                });
              }}
            />
          )}

          {boolValue.deleteAcountModel && (
            <CommonModel
              title={"Delete Your Account"}
              onClose={() => {
                setBoolValue({
                  ...boolValue,
                  deleteAcountModel: false,
                });
              }}
              name="DeleteAccount"
            />
          )}
          {boolValue.trialModel && closed && (
            <TrialFinishModel
              title={"Change Your Skydive Subscription"}
              onClose={() => {
                setBoolValue({
                  ...boolValue,
                  trialModel: false,
                });
              }}
              name="ChangeSub"
            />
          )}

          {boolValue.paymentModel && closed && (
            <Payment
              onClose={() => {
                setBoolValue({
                  ...boolValue,
                  paymentModel: false,
                });
              }}
              subscriptionData={authReducer?.accountRecord}
              paymentMethod={paymentMethod}
            />
          )}
          {boolValue.changePaymentModel && (
            <Elements stripe={stripePromise}>
              <PaymentMethod
                onClose={() => {
                  getPaymentMethod();

                  setBoolValue({
                    ...boolValue,
                    changePaymentModel: false,
                  });
                }}
              />
            </Elements>
          )}
        </>
      ) : (
        <h1>You are offline</h1>
      )}
    </div>
  );
};

export default Settings;

