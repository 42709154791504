import io from "socket.io-client";
import config from "../config/config";


export let socket: any;
export let socketForConversionSetup: any;

export const socketConnection = () => {
  return socket;
};

export const connectSocketForAnalyticsView = (
  socketOptions: any,
  readyCb?: () => any
) => {
  
  if (socket) {
    if (readyCb) {
      readyCb();
    }
    return socket;
  } else {
    // @ts-ignore
    socket = io.connect(config.socketServerPath, socketOptions);
    socket.on("connect", () => {
      if (readyCb) {
        readyCb();
      }
    });
    return socket;
  }
};
export const connectSocketForConversionSetup = (socketOptions: any) => {
 
  if (socketForConversionSetup) {
    return socketForConversionSetup;
  } else {
    // @ts-ignore
    socketForConversionSetup = io.connect(
      config.socketServerPath,
      socketOptions
    );
   
    return socketForConversionSetup;
  }
};
export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
  }
  if (socketForConversionSetup) {
    socketForConversionSetup.disconnect();
  }
};
export const disconnectSocketForConversionSetup = () => {

  if (socketForConversionSetup) {
    socketForConversionSetup.disconnect();
  }
};


