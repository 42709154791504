import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import OutsideAlerter from "../utilities/useOutSideComponentClick";

import ROUTES from "../../constants/routes";

import Logo from "../../assets/images/Skydive_logo_beta.svg";
import SettingsIcon from "../../assets/images/settings.png";
import HelpIcon from "../../assets/images/help.png";
import HelpDropdownIcon from "../../assets/images/help-icon-dropdown.svg";
import HeadphonesIcon from "../../assets/images/headphones-icon.svg";
import LoudspeakerIcon from "../../assets/images/loudspeaker.svg";
import ScissorsIcon from "../../assets/images/scissors.svg";
import "./Header.scss";

const Header = () => {
  const [setDropdown, showDropdown] = useState(false);
  const authReducer: any = useSelector((state: any) => state?.AuthReducer);

  const handleOutsideClick = () => {
    showDropdown(false);
  };
  return (
    <header className="header">
      <Link to="https://www.theskydiveapp.com/">
        <img src={Logo} alt="logo" className="logo" />
      </Link>

      <h5 className="hover-tooltip">
        {window.location.pathname ===
        (window.location.pathname.split("/")[
          window.location.pathname.split("/").length - 1
        ] === "conversion-setup"
          ? ROUTES.CONVERSION_SETUP.replace(":id", "")
          : ROUTES.CONVERSION_SETUP.replace(
              ":id",
              window.location.pathname.split("/")[
                window.location.pathname.split("/").length - 1
              ]
            ))
          ? ""
          : authReducer.authData.site.host}
        {window.location.pathname !==
          (window.location.pathname.split("/")[
            window.location.pathname.split("/").length - 1
          ] === "conversion-setup"
            ? ROUTES.CONVERSION_SETUP.replace(":id", "")
            : ROUTES.CONVERSION_SETUP.replace(
                ":id",
                window.location.pathname.split("/")[
                  window.location.pathname.split("/").length - 1
                ]
              )) && (
          <span className="tooltip-content tooltip-content-bottom header-tooltip">
            {authReducer.authData.site.host}
          </span>
        )}
      </h5>

      <ul>
        <li>
          <Link
            to={
              window.location.pathname ===
              (window.location.pathname.split("/")[
                window.location.pathname.split("/").length - 1
              ] === "conversion-setup"
                ? ROUTES.CONVERSION_SETUP.replace(":id", "")
                : ROUTES.CONVERSION_SETUP.replace(
                    ":id",
                    window.location.pathname.split("/")[
                      window.location.pathname.split("/").length - 1
                    ]
                  ))
                ? ""
                : window.location.pathname.split("/")[
                    window.location.pathname.split("/").length - 1
                  ] === "analytics-view"
                ? ROUTES.SETTINGS.replace(":id", "")
                : ROUTES.SETTINGS.replace(
                    ":id",
                    window.location.pathname.split("/")[
                      window.location.pathname.split("/").length - 1
                    ]
                  )
            }
          >
            <img src={SettingsIcon} alt="icon" />
            Settings
          </Link>
        </li>
        <OutsideAlerter onOutsideClick={handleOutsideClick}>
          <li onClick={() => showDropdown(!setDropdown)}>
            <a>
              <img src={HelpIcon} alt="icon" />
              Help
            </a>
            {setDropdown ? (
              <div className="custom-dropdown">
                <div className="arrow-up" />
                <ul>
                  <li>
                    <Link
                      to="https://skydive.zendesk.com/hc/en-us"
                      target="_blank"
                    >
                      <img src={HelpDropdownIcon} alt="icon" /> Support Center
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://go.crisp.chat/chat/embed/?website_id=a14a2c2a-be82-4404-a9cd-6c115663c0b0"
                      target="_blank"
                    >
                      <img src={HeadphonesIcon} alt="icon" /> Live Chat
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link
                      to="https://feedback.userreport.com/1cd16c50-c1e9-43d9-afd1-004b4f461e36/#submit/idea"
                      target="_blank"
                    >
                      <img src={LoudspeakerIcon} alt="icon" /> Suggest a Feature
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://feedback.userreport.com/1cd16c50-c1e9-43d9-afd1-004b4f461e36/#submit/bug"
                      target="_blank"
                    >
                      <img src={ScissorsIcon} alt="icon" /> Submit a Bug
                    </Link>
                  </li>
                </ul>
              </div>
            ) : null}
          </li>
        </OutsideAlerter>

      
      </ul>
     
    </header>
  );
};

export default Header;
